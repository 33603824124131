// Placeholder for environment settings - DO NOT EDIT.
// This file overwritten when run via the docker image. Edit assets/environments json files instead.
export const environment = {
    production: false,
    settings: {
        keycloakIssuer: '',
        keycloakChangePasswordUrl : '',
        api : '',
        keycloak: {
            url: '',
            realm: '',
            clientId: '',
            scopes: ''
        }
    }
};
