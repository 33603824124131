import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillOverviewComponent } from './billing/bill-overview.component';
import { AuthGuard } from './services/auth-guard';
import { AdminComponent } from './admin/admin.component';

const appRoutes: Routes = [
    { path: '', component: BillOverviewComponent, canActivate: [AuthGuard], data: { period: 'monthly' } },
    { path: 'daily', component: BillOverviewComponent, canActivate: [AuthGuard], data: { period: 'daily' } },
    { path: 'weekly', component: BillOverviewComponent, canActivate: [AuthGuard], data: { period: 'weekly' } },
    { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
    { path: '**', redirectTo: '' }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes, {});
