<table class="hover stack">
    <thead>
        <tr>
            <th width="35%">
                <span class="pointer" style="margin-left:10px;" (click)="sortBills(false)">Filnavn</span>
            </th>
            <th class="text-center">
                <span class="pointer" (click)="sortBills(true)">Dato</span>
            </th>
            <th class="text-center" width="150">Download</th>
        </tr>
    </thead>
    <tbody class="list">
        <tr *ngFor="let bill of bills | orderBy : ['-date']">
            <td><span style="margin-left:10px;">{{ bill.filenameToShow }}</span></td>
            <td class="text-center">{{ bill.date | date:'mediumDate'}}</td>
            <td class="text-center">
                <span class="fa fa-download pointer" (click)="downloadBill(bill.fileName)"></span>
            </td>
        </tr>
    </tbody>
</table>