/* src/app/app.component.scss */
.top-bar {
  padding: 0px;
  background: #2e353d;
  border-bottom: 5px solid #f26c5b;
}
.top-bar ul {
  background: #2e353d;
}
.top-bar ul li {
  background: #2e353d;
}
.top-bar ul li a {
  color: #fff;
}
.menu a {
  position: relative;
}
.menu > li > a {
  padding-bottom: 19px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 19px;
}
.menu a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: -5px;
  left: 0;
  background-color: #973124;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.menu a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  color: #e8e9eb;
}
.content {
  margin-top: 1em;
}
/*# sourceMappingURL=app.component.css.map */
