import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Bill } from '../models';
import { BillingService } from '../services/billing.service';
import { OrderByPipe } from '../pipes/order-by.pipe';
import * as fileSaver from 'file-saver';

@Component({
    selector: 'bill-table',
    templateUrl: 'bill-table.component.html',
    styleUrls: ['bill-table.component.css'],
    standalone: false
})
export class BillTableComponent {
    @Input() bills: Bill[];
    private fileNameAscending:boolean;
    private dateAscending:boolean;

    constructor(private billingService:BillingService, private router: Router) {
        this.billingService = billingService;
        this.fileNameAscending = false;
        this.dateAscending = false;
    }

    sortBills(sortByDate:boolean) {
        let sortOrder: string;
        if (sortByDate) {
            if (this.dateAscending) {
                sortOrder = '-date';
                this.dateAscending = false;
            }
            else {
                sortOrder = '+date';
                this.dateAscending = true;
            }
        }
        else {
            if (this.fileNameAscending) {
                sortOrder = '-filenameToShow';
                this.fileNameAscending = false;
            }
            else {
                sortOrder = '+filenameToShow';
                this.fileNameAscending = true;
            }
        }
        new OrderByPipe().transform(this.bills, [sortOrder]);
    }

    downloadBill(fileName: string) {
        this.billingService.downloadBill(fileName).subscribe(
            data => {
                fileSaver.saveAs(data, fileName);
            }
        );
    }
}
