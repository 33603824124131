/// <reference path="../../typings/index.d.ts" />
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Bill } from '../models';
import { BillingService } from '../services/billing.service';
import { DateTime } from 'luxon';

declare var List: any;

@Component({
    selector: 'bill-overview',
    templateUrl: 'bill-overview.component.html',
    standalone: false
})
export class BillOverviewComponent implements OnInit {
    public error: string;
    public bills: Bill[];
    public showSpinner: boolean;
    public period: string;

    from: Date = DateTime.local().startOf('year').minus({ years: 1 }).toJSDate();
    to: Date = new Date();

    constructor(private billingService: BillingService, private router: Router, private route: ActivatedRoute) {
        this.billingService = billingService;
        this.showSpinner = false;
    }

    ngOnInit() {
        this.period = this.route.snapshot.data['period'];
        this.getBills();
    }

    dateChanged(): void {
        this.getBills();
    }

    getBills() {
        this.showSpinner = true;
        this.billingService.getBillingsForHaulage(this.from, this.to, this.period).subscribe(
            response => {
                this.bills = <Bill[]>response;
                this.showSpinner = false;
            }, error => {
                this.error = 'Vi kan desværre ikke hente dine betalingsgrundlag i øjeblikket. Prøv igen senere.';
                this.showSpinner = false;
            });
    }
}
