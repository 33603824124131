import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Haulage } from '../models/Haulage';
import { ApiResponse } from '../models/ApiResponse';

@Injectable()
export class FleetService {
    private _billApi = environment.settings.api;

    constructor(private http: HttpClient) {
    }

    async createFleet(haulage: Haulage): Promise<ApiResponse> {
        return this.http.post<ApiResponse>(this._billApi + '/fleet', haulage).toPromise();
    }
}