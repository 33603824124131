// Generated using typescript-generator version 1.8.202 on 2016-05-24 14:02:00.

export interface BaseModel {
    id: string;
    created: Date;
    updated: Date;
}

export class LoginModel {
    email: string;
    password: string;

    clear() {
        this.email = '';
        this.password = '';
    }
}

export class Bill {
    drivingAgreement: number;
    fileName: string;
    date: number;
    fleetName: string;
    filenameToShow: string;
}

export class PasswordUpdateModel {
    currentPassword: string;
    newPassword: string;
}

export class PasswordUpdateExpiredPasswordModel {
    currentPassword: string;
    newPassword: string;
    email: string;
}

export class UnsubscribeUpdateModel {
    unsubscribe: boolean;
}

export class Person {
    cpr: string;
    firstName: string;
    middleName: string;
    lastName: string;
    streetName: string;
    houseNumber: string;
    floor: string;
    door: string;
    city: string;
    zipCode: number;

    public getAddress():string {
        if (this.floor != null && this.door != null) {
            return this.streetName + ' ' + this.houseNumber + ' ' + this.floor + ' ' + this.door;
        }
        else if (this.floor != null) {
            return this.streetName + ' ' + this.houseNumber + ' ' + this.floor;
        }
        else if (this.door != null) {
            return this.streetName + ' ' + this.houseNumber + ' ' + this.door;
        }
        else if (this.streetName != null && this.houseNumber != null) {
            return this.streetName + ' ' + this.houseNumber;
        }
        return '';
    }

    public getFullName():string {
        if (this.middleName) {
            return this.firstName + ' ' + this.middleName + ' ' + this.lastName;
        }
        return this.firstName + ' ' + this.lastName;
    }

    public mapJson(json:any):void {
        this.cpr = json.cpr;
        this.firstName = json.firstName;
        this.middleName = json.middleName;
        this.lastName = json.lastName;
        this.streetName = json.streetName;
        this.houseNumber = json.houseNumber;
        this.floor = json.floor;
        this.door = json.door;
        this.city = json.city;
        this.zipCode = json.zipCode;
    }
}

export class User extends Person {
    id: string;
    cellPhoneNumber: string;
    landlinePhoneNumber: string;
    email: string;
    flexTrafikConsent: boolean;
    marketingConsent: boolean;
    password: string;

    public mapJson(json:any):void {
        super.mapJson(json);
        this.id = json.id;
        this.cellPhoneNumber = json.cellPhoneNumber;
        this.landlinePhoneNumber = json.landlinePhoneNumber;
        this.email = json.email;
        this.flexTrafikConsent = json.flexTrafikConsent;
        this.marketingConsent = json.marketingConsent;
        this.password = json.password;
    }
}

export enum UserScope {
	CITIZEN, USER
}

export class PasswordFormOutput {
    password: string;
    isValid: boolean;

    constructor(password:string, isValid:boolean) {
        this.password = password;
        this.isValid = isValid;
    }
}