/* angular:styles/component:css;6d6a8f8706144f205b057e6c74a386422ecf3920eacd4faa03828bc2582ce5ea;/usr/src/app/src/app/spinner/spinner.component.ts */
.sk-three-bounce {
  margin: 40px auto;
  width: 80px;
  text-align: center;
}
.sk-three-bounce .sk-child {
  width: 20px;
  height: 20px;
  background-color: #f26c5b;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}
.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-three-bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-three-bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/*# sourceMappingURL=spinner.component.css.map */
