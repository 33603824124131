import { NgModule, APP_INITIALIZER, DoBootstrap, ApplicationRef } from '@angular/core';

import { routing, appRoutingProviders } from './app.routing';

import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthGuard } from './services/auth-guard';
import { BillTableComponent } from './billing/bill-table.component';
import { BillOverviewComponent } from './billing/bill-overview.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './spinner/spinner.component';
import { AuthorizationInterceptor } from './services/authorization.interceptor';
import { BillingService } from './services/billing.service';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from './cookieconsent.config';
import { KeycloakService, KeycloakAngularModule, KeycloakEventType, KeycloakEventTypeLegacy } from 'keycloak-angular';
import { AdminComponent } from './admin/admin.component';
import { FleetService } from './services/fleet.service';
import { environment } from '../environments/environment';
import { AudienceValidatorService } from './services/audience-validator.service';

const keycloakService = new KeycloakService();

@NgModule({ declarations: [
    AppComponent,
    BillTableComponent,
    BillOverviewComponent,
    OrderByPipe,
    SpinnerComponent,
    AdminComponent
], imports: [BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    routing,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    AlertModule.forRoot(),
    KeycloakAngularModule], providers: [
    appRoutingProviders,
    AuthGuard,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthorizationInterceptor,
        multi: true
    },
    {
        provide: KeycloakService,
        useValue: keycloakService
    },
    BillingService,
    FleetService,
    AudienceValidatorService,
    provideHttpClient(withInterceptorsFromDi())
] })
export class AppModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        keycloakService.init({
            config: {
                url: environment.settings.keycloak.url,
                realm: environment.settings.keycloak.realm,
                clientId: environment.settings.keycloak.clientId
            },
            initOptions: {
                onLoad: 'login-required',
                checkLoginIframe: false
            },
            loadUserProfileAtStartUp: false,
            enableBearerInterceptor: false,
            bearerExcludedUrls: ['/assets']
        }).then(() => {
            appRef.bootstrap(AppComponent);

            keycloakService.keycloakEvents$.subscribe(e => {
                if (e.type == KeycloakEventTypeLegacy.OnTokenExpired) {
                    keycloakService.logout(); // log person out when token expires.
                    /* keycloakService.updateToken(180).then(() => { // automatically refreshes an expired token
                        // Do nothing
                    }); */
                }
            });
        });
    }
}
