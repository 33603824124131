import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Haulage } from '../models/Haulage';
import { Fleet } from '../models/Fleet';
import { User } from '../models';
import { FleetService } from '../services/fleet.service';
import { ApiResponse } from '../models/ApiResponse';
import { Observable, forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

class RawEntry {
    content: string;
    valid: boolean = false;
    processed: boolean = false;
    error: string = null;
}

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrls: ['admin.component.scss'],
    standalone: false
})
export class AdminComponent implements OnInit {
    constructor(private formBuilder: UntypedFormBuilder, private fleetService: FleetService) { }

    form: UntypedFormGroup;
    fileControl: UntypedFormControl;
    ptoControl: UntypedFormControl;
    fleetTypeControl: UntypedFormControl;

    rawEntries: RawEntry[] = [];
    processing: boolean = false;

    ngOnInit() {
        this.fileControl = new UntypedFormControl('', Validators.required);
        this.ptoControl = new UntypedFormControl('', Validators.required);
        this.fleetTypeControl = new UntypedFormControl('', Validators.required);
        this.form = this.formBuilder.group({
            file: this.fileControl,
            pto: this.ptoControl,
            fleetType: this.fleetTypeControl
        });
    }

    fileChange($event: any) {
        if ($event.target.files && $event.target.files[0]) {
            this.rawEntries = [];

            const file = $event.target.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const csvData = reader.result;
                this.rawEntries = [];
                (<string>csvData).split(/\r\n|\n/).forEach(s => {
                    const entry = new RawEntry();
                    entry.content = s.trim();
                    entry.valid = this.verifyEntry(entry.content);

                    if (!entry.valid) {
                        this.form.controls['file'].setErrors({
                            invalidEntries: 'Some entries did not parse validation'
                        });
                    }

                    this.rawEntries.push(entry);
                });
            };

            reader.onerror = (e) => {
                console.log(e);
            };

            reader.readAsText(file);
        }
    }

    async importData() {
        this.processing = true;

        for (let i = 0; i < this.rawEntries.length; i++) {
            const entry = this.rawEntries[i];
            const parts = entry.content.split(',');
            const fleet = new Fleet();
            fleet.name = parts[1].trim();
            fleet.pto = this.form.controls['pto'].value;
            fleet.fleetType = this.form.controls['fleetType'].value;

            const user = new User();
            const userNameParts = parts[3].trim().split(' ');
            user.firstName = userNameParts[0];
            user.lastName = userNameParts.slice(1, userNameParts.length).join();
            user.email = parts[4].trim();

            const haulage = new Haulage();
            haulage.fleet = fleet;
            haulage.cvr = parts[0].trim();
            haulage.companyName = parts[2].trim();
            haulage.users.push(user);

            try {
                await this.fleetService.createFleet(haulage);
                entry.processed = true;
            } catch (response) {
                entry.processed = true;
                entry.error = response.error.errors.join('\n');
            }
        }

        this.processing = false;
    }

    verifyEntry(entry: string): boolean {
        const pattern = /^\d+,.+?,.+?,.+?,.+?\@.+\..+\w$/;
        return entry.match(pattern) != null;
    }

    getHeaderArray(csvRecordsArr: any) {
        const headers = (<string>csvRecordsArr[0]).split(',');
        const headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }
}
