<div id="bill-overview" class="grid-container">
    <div class="grid-x grid-margin-x">
        <div class="cell">
            <h3>Betalingsgrundlag</h3>
        </div>

        <div class="cell">
            <ul class="tabs" data-tabs id="nav-tabs">
                <li class="tabs-title"><a routerLink="/monthly" [attr.aria-selected]="period == 'monthly'"><b>Månedsliste</b></a></li>
                <li class="tabs-title"><a routerLink="/weekly" [attr.aria-selected]="period == 'weekly'"><b>Ugeliste</b></a></li>
                <li class="tabs-title"><a routerLink="/daily" [attr.aria-selected]="period == 'daily'"><b>Dagsliste</b></a></li>
            </ul>
        </div>
    </div>

    <div class="grid-x grid-padding-x">
        <div class="cell medium-6">
            <label>Fra dato
                <input type="text" [(ngModel)]="from" (ngModelChange)="dateChanged()" placeholder="Datepicker" bsDatepicker />
            </label>
        </div>
        <div class="cell medium-6">
            <label>Til dato
                <input type="text" [(ngModel)]="to" (ngModelChange)="dateChanged()" placeholder="Datepicker" bsDatepicker />
            </label>
        </div>
    </div>

    <div *ngIf="bills && bills.length > 0">
        <div class="row">
            <div class="large-12 columns" id="bill-table">
                <bill-table [bills]="bills"></bill-table>
            </div>
        </div>
    </div>

    <div *ngIf="bills && bills.length == 0">
        <div class="row">
            <div class="large-12 columns">
                <p>Der er desværre ingen betalingsgrundlag at vise i øjeblikket.</p>
            </div>
        </div>
    </div>

    <div *ngIf="error" data-alert class="alert-danger alert radius">
        {{error}}
    </div>
</div>

<div class="large-12 columns">
    <flx-spinner [show]="showSpinner"></flx-spinner>
</div>
