/// <reference path="../typings/index.d.ts" />
import {AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { KeycloakService } from 'keycloak-angular';
import { AudienceValidatorService } from './services/audience-validator.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: false
})

export class AppComponent implements OnInit, AfterViewInit {
    public showSpinner = false;
    public audienceValid: Boolean = null;

    constructor(private el:ElementRef,
        private ccService: NgcCookieConsentService,
        public keycloak: KeycloakService,
        private audienceValidator: AudienceValidatorService) {}

    ngOnInit() {
        if (!this.ccService.hasConsented()) {
            this.ccService.open();
        }

        this.keycloak.getToken().then(token => {
            this.audienceValid = this.audienceValidator.validate(token);
        });

        $('.reveal').on('open.fndtn.reveal', '[data-reveal]', function() {
            $('body').addClass('modal-open');
        });
        $('.reveal').on('close.fndtn.reveal', '[data-reveal]', function() {
            $('body').removeClass('modal-open');
        });

        $(document).on('open.fndtn.reveal', function() {
            $('html').addClass('is-reveal-open');
        });

        $(document).on('close.fndtn.reveal', function() {
            $('html').removeClass('is-reveal-open');
        });
    }

    ngAfterViewInit() {
        $(this.el.nativeElement).foundation();
    }

    public changePassword() {
        window.location.href = environment.settings.keycloakChangePasswordUrl;
    }

    public logout() {
        this.showSpinner = true;
        this.keycloak.logout();
        this.showSpinner = false;
    }
}
