import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { environmentLoader as environmentLoaderPromise } from './environments/environmentLoader';

environmentLoaderPromise.then(env => {

    environment.production = env.production;
    if (environment.production) {
        enableProdMode();
    }

    environment.settings = env.settings;
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
});