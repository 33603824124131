import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { KeycloakService } from 'keycloak-angular';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

// Intercepts all HTTP requests made by angular/common/http/HttpClient
// by adding the Authorization header with the current JWT token
@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
    constructor(private keycloakAngular: KeycloakService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.keycloakAngular.getToken()).pipe(mergeMap(token => {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });

            return next.handle(request);
        }));
    }
}
