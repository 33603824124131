import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Bill } from '../models';
import { environment } from '../../environments/environment';
import { DateTime } from 'luxon';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';



@Injectable()
export class BillingService {
    private _billApi = environment.settings.api;

    constructor(private http: HttpClient, private keycloakAngular: KeycloakService) {
    }

    getBillingsForHaulage(from: Date = DateTime.local().startOf('year').minus({ years: 1 }).toJSDate(),
        to: Date = DateTime.local().toJSDate(), period: string = 'monthly'): Observable<Bill[]> {
        this.keycloakAngular.updateToken(300);
        return this.http.get<Bill[]>(this._billApi + '/billings', {
            params: {
                from: from.toISOString(),
                to: to.toISOString(),
                period: period.toString()
            }
        }).pipe(
            catchError(this.handleError));
    }

    downloadBill(fileName: String): Observable<Blob> {
        this.keycloakAngular.updateToken(300);

        return this.http.get(this._billApi + '/billing?filename=' + fileName, {
            responseType: 'blob'
        }).pipe(
            catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        // In a real world app, we might send the error to remote logging infrastructure
        const errMsg = error.message || 'Server error';
        return observableThrowError(error);
    }
}
