<div class="grid-container">
    <form [formGroup]="form">
        <div class="grid-x grid-margin-x">
            <div class="grid small-12">
                <h3>Administration</h3>
            </div>
        </div>
        <div class="grid-x grid-padding-x">
            <div class="cell medium-6">
                <label>CSV fil med nye åkarier
                    <input type="file" (change)="fileChange($event)" placeholder="Upload CSV" accept=".csv"
                        formControlName="file" />
                </label>
            </div>
        </div>
        <div class="grid-x grid-padding-x" *ngIf="fileControl.invalid && (fileControl.dirty || fileControl.touched)">
            <div class="cell medium-6">
                <alert type="warning">
                    <div *ngIf="fileControl.errors.required">
                        Fil er påkrævet.
                    </div>
                    <div *ngIf="fileControl.errors.invalidEntries">
                        Filen indeholder linjer der ikke kan valideres.
                    </div>
                </alert>
            </div>
        </div>
        <div class="grid-x grid-padding-x">
            <div class="cell medium-6">
                <label>Trafikselskab
                    <select formControlName="pto" required>
                        <option value="" disabled>Vælg Trafikselskab</option>
                        <option value="MOVIA">Movia</option>
                        <option value="NT">NT</option>
                        <option value="SYDTRAFIK">Sydtrafik</option>
                        <option value="MIDTTRAFIK">Midttrafik</option>
                        <option value="FYNBUS">Fynbus</option>
                    </select>
                </label>
            </div>
        </div>
        <div class="grid-x grid-padding-x">
            <div class="cell medium-6">
                <label>Type
                    <select formControlName="fleetType" required>
                        <option value="" disabled>Vælg Trafiktype</option>
                        <option value="Vognmand">Vognmand</option>
                        <option value="Myndighed">Myndighed</option>
                    </select>
                </label>
            </div>
        </div>
        <div class="grid-x grid-padding-x" *ngIf="ptoControl.invalid && (ptoControl.dirty || ptoControl.touched)">
            <div class="cell medium-6">
                <alert type="warning">
                    <div *ngIf="ptoControl.errors.required">
                        Trafikselskab er påkrævet.
                    </div>
                </alert>
            </div>
        </div>
        <div class="grid-x grid-padding-x" *ngIf="!processing">
            <div class="cell medium-12">
                <button type="button" class="button" (click)="importData()" [disabled]="form.invalid || processing">Importer
                    Data</button>
            </div>
        </div>
        <div class="grid-x grid-padding-x" *ngIf="processing">
            <div class="cell medium-12">
                <flx-spinner [show]="processing"></flx-spinner>
            </div>
        </div>
        <div class="grid-x grid-padding-x" *ngIf="rawEntries.length > 0">
            <div class="cell medium-12">
                <h4>Preview</h4>
                <table>
                    <tr *ngFor="let entry of rawEntries" [class.error]="!entry.valid || (entry.processed && entry.error != null)" [class.success]="entry.processed && entry.error == null">
                        <td>{{entry.content}}</td>
                        <td *ngIf="entry.processed">{{entry.error}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </form>
</div>
