import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: window.location.hostname
    },
    palette: {
        popup: {
            background: '#2e353d'
        },
        button: {
            background: '#f26c5b',
            text: '#fff'
        }
    },

    theme: 'classic',
    type: 'info',
    content: {
        message: 'Her bruger vi cookies. ' +
            'Det er en lille tekstfil, der husker dine indstillinger, så du får en bedre brugeroplevelse. ' +
            ' Når du klikker dig videre på siden, accepterer du dette. ' +
            'Du kan læse mere detaljeret omkring vores anvendelse af cookies',
        dismiss: 'OK',
        link: 'her',
        href: '/privacy'
    }
};
