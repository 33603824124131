import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AudienceValidatorService {
    private helper = new JwtHelperService();

    validate(token: string): boolean {
        const jwt = this.helper.decodeToken(token);
        const audience = jwt.aud;

        if (audience instanceof Array) {
            const audiences = <Array<string>>audience;

            if (audiences.indexOf('billing-info-service') == -1) {
                return false;
            }
        } else {
            if (audience != 'billing-info-service') {
                return false;
            }
        }

        return true;
    }
}