<div [class.fadeout]="showSpinner" class="page-wrap">
    <div class="title-bar" data-responsive-toggle="main-menu" data-hide-for="medium">
        <button class="menu-icon" type="button" data-toggle></button>
        <div class="title-bar-title">Menu</div>
    </div>
    <div class="top-bar">
        <div class="top-bar-left">
            <ul class="dropdown menu" data-responsive-menu="drilldown medium-dropdown" data-dropdown-menu>
                <li><a routerLink="/"><strong>FLEX</strong>TRAFIK</a></li>
            </ul>
        </div>
        <div class="top-bar-right">
            <ul class="menu" data-responsive-menu="drilldown medium-dropdown">
                <li *ngIf="keycloak.isUserInRole('create-fleet-*', 'billing-info-service')"><a routerLink="/admin">Administration</a></li>
                <li><a (click)="changePassword()">Ændre adgangskode</a></li>
                <li><a (click)="logout()">Log ud</a></li>
            </ul>
        </div>
    </div>
    <div class="content">
        <div class="grid-container" *ngIf="audienceValid == false">
            <div class="grid-x grid-margin-x">
                <div class="grid small-12">
                    <alert type="warning">
                        Du er ikke tilknyttet en gruppe og har derfor ikke adgang til at se bilag.
                    </alert>
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
<footer>
    <div class="grid-container">
        <div class="grid-x grid-margin-x">
            <div class="cell small-1"></div>
            <div class="cell small-2">
                <p><img src="/assets/images/logos/nt.svg" alt="nt" width="100%" height="" /></p>
            </div>
            <div class="cell small-2">
                <p><img src="/assets/images/logos/midttrafik_dark.png" alt="midttrafik" width="100%" height="" />
                </p>
            </div>
            <div class="cell small-2">
                <p><img src="/assets/images/logos/sydtrafik.svg" alt="sydtrafik" width="100%" height="" /></p>
            </div>
            <div class="cell small-2">
                <p><img src="/assets/images/logos/fynbus.svg" alt="fynbus" width="100%" height="" /></p>
            </div>
            <div class="cell small-2">
                <p><img src="/assets/images/logos/movia.svg" alt="movia" width="100%" height="" /></p>
            </div>
        </div>
        <div class="grid-x grid-margin-x">
            <div class="cell small-1 medium-3"></div>
            <div class="cell small-10 medium-6 text-center">
                <h5>Problemer på rejsen?</h5>
                <p>Ring til vores kundecenter på <a href="tel:+4599341134">99 34 11 34</a></p>
            </div>
        </div>
    </div>
</footer>
<flx-spinner class="spinner-center-position" [show]="showSpinner"></flx-spinner>
