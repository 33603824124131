import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { AudienceValidatorService } from './audience-validator.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(protected router: Router, protected keycloakAngular: KeycloakService, private audienceValidator: AudienceValidatorService) {
        super(router, keycloakAngular);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.authenticated) {
                this.keycloakAngular.login({
                    scope: environment.settings.keycloak.scopes,
                    redirectUri: window.location.origin
                });
                return;
            }

            this.keycloakAngular.getToken().then(token => {
                this.keycloakAngular.updateToken(300);
                if (!this.audienceValidator.validate(token)) {
                    resolve(false);
                    return;
                }

                const requiredRoles = route.data.roles;
                if (!requiredRoles || requiredRoles.length === 0) {
                    return resolve(true);
                } else {
                    if (!this.roles || this.roles.length === 0) {
                        resolve(false);
                    }
                    let granted = false;
                    for (const requiredRole of requiredRoles) {
                        if (this.roles.indexOf(requiredRole) > -1) {
                            granted = true;
                            break;
                        }
                    }
                    resolve(true);
                }
            }).catch(() => {
                resolve(false);
            });
        });
    }
}
